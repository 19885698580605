<template>
  <div>
    <CMBtnIcon
      icon="mdi-delete"
      :small="false"
      :callback="() => openDialog()"
    />
    <v-dialog
      v-model="showConfirmation"
      max-width="400"
      overlay-opacity="0.6"
    >
      <ConfirmDelete
        :text="$t('hcm.delete_confirm_reserve')"
        :items="elementToDelete"
        :error-msg="errorMessage"
        @deleteCancel="deleteCancel"
        @deleteConfirmation="deleteConfirmation"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import vuexMixin from '@/mixins/vuexMixin'
import HCReservationMixin from '@/mixins/Heatmaps/HCReservationMixin'

export default {
  name: 'ConfirmDeleteReserve',
  components: {
    ConfirmDelete: () => import('@/components/Common/CMDeleteConfirmation.vue'),
    CMBtnIcon: () => import('@/components/Common/CMBtnIcon.vue')
  },
  mixins: [vuexMixin, HCReservationMixin],
  props: {
    itemId: {
      type: Number,
      default: null,
      required: true
    }
  },
  data () {
    return {
      showConfirmation: false,
      elementToDelete: undefined,
      errorMessage: undefined
    }
  },
  computed: {
    ...mapState({
      ReservationToDelete: function ({ hostingCapacity }) {
        return this.itemId
          ? hostingCapacity.HCReservations.find((reserve) => reserve.id === this.itemId)
          : null
      }
    })
  },

  methods: {
    openDialog () {
      if (this.itemId) {
        const idText = `ID: ${this.ReservationToDelete.id}`
        const reserveText = `${this.$t('hcm.reserve_kw')}: ${this.ReservationToDelete.capacity}`
        const networkText = `${this.$t('grid.network')}: ${this.ReservationToDelete.network}`
        this.elementToDelete = [idText, reserveText, networkText]
        this.showConfirmation = true
        this.errorMessage = undefined
      }
    },
    deleteCancel () {
      this.showConfirmation = false
      this.elementToDelete = undefined
      this.errorMessage = undefined
    },
    async deleteConfirmation () {
      const deleteResponse = await this.removeReservationItem(this.itemId)
      if (deleteResponse) {
        this.showConfirmation = false
        this.elementToDelete = undefined
      } else {
        this.errorMessage = this.$t('hcm.delete_reserve_error')
      }
    }
  }
}
</script>
<style scoped>
  ::v-deep .v-dialog {
    background-color: #272727 !important;
  }
</style>
